.container {
    display: contents;
    position: absolute;
}

.detail-container {
    overflow: scroll;
    position: absolute;
    right: 9vmin;
    top: 25vmin;
    width: 62vmin;
    height: 62vmin;
}

.detail-title {
    transform: translateX(20.8vmin);
    width: 38vmin;
    height: 7.5vmin;
    user-select: none;
    padding: 1.1vmin;
    color: var(--main-bg-color);
    box-shadow: 1vmin 1vmin 1vmin var(--soft-txt-color);
    background: var(--main-txt-color);
    font-size: 3.5vmin;
    vertical-align: baseline;
}

.detail-description {
    transform: translateX(20.8vmin);
    width: 38vmin;
    height: 11.5vmin;
    margin-top: 0.8vmin;
    user-select: none;
    padding: 1.1vmin;
    color: var(--main-bg-color);
    box-shadow: 1vmin 1vmin 1vmin var(--soft-txt-color);
    background: var(--main-txt-color-transparent);
    font-size: 2vmin;
    vertical-align: baseline;
}

.detail-text {
    user-select: none;
    margin-top: 1vmin;
    padding: 1.1vmin;
    color: var(--main-txt-color);
    box-shadow: 2px 2px 2px var(--soft-txt-color);
    background: var(--main-panel-color);
    font-size: 2vmin;
    vertical-align: baseline;
    text-align: left;
}

.detail-text img {
    width: 100%;
}

.grid-container {
    display: grid;
    grid-template-columns: 20vmin 20vmin 20vmin;
    grid-template-rows: 20vmin 20vmin 20vmin;
    grid-gap: 1vmin;
    background-color: transparent;
    padding: 1vmin;
    position: absolute;
    top: 24vmin;
    left: 8vmin;
}

.grid-background {
    position: absolute;
    top: 23.5vmin;
    left: 7.5vmin;
    background-color: var(--main-bg-color);
    height: 65vmin;
    width: 65vmin;
    border-radius: 5%;
}

.grid-button {
    position: absolute;
    top: .5vmin;
    right: 35.5vmin;
    z-index: 1;
    border: var(--soft-txt-color) 1px solid;
    border-radius: 15%;
    color: var(--main-txt-color);
    background-color: var(--main-bg-color);
    font-size: 2vmin;
    text-align: center;
    padding: 0.7vmin 2vmin;
    user-select: none;
}

.grid-button:hover {
    background-color: var(--soft-txt-color);
}

.button-extension {
    position: absolute;
    width: 15vmin;
    height: 15vmin;
    top: 4vmin;
    right: -2.8vmin;
}