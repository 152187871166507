.grid-item {
    background-color: var(--main-panel-color);
    border: none;
    border-radius: 25%;
    box-shadow: 1vmin 1vmin 1vmin var(--soft-txt-color);
    font-size: 1.7vmin;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: opacity 0.7s ease-in-out, transform 3s ease-in-out;
}

.grid-item:hover{
    background-size: 90%;
}

.grid-item:active{
    filter: grayscale()
}
.grid-item-text {
    position: relative;
    top: 15.35vmin;
    user-select: none;
    padding: 1.1vmin;
    color: var(--main-bg-color);
    background: var(--main-txt-color-transparent);
}

.grid-item-description{
    font-size: 1.5vmin;
    padding: 10px;
}

.selected{
    pointer-events: none;
}