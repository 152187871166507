.child-img {
    height: 14vmin;
    box-shadow: 0.5vmin 0.5vmin 0.5vmin var(--soft-txt-color);
    border-radius: 50%;
    cursor: pointer;
}

.child-img:hover {
    padding: 5px;
}

.child-img:active {
    filter: grayscale()
}

.parent {
    position: absolute;
    top: 27.5vmin;
    right: 25.5vmin;
    width: 30vmin;
    height: 30vmin;
    border-radius: 50%;
    transition: transform 0.7s ease-in-out;
}

.child {
    position: relative;
    width: 14vmin;
    height: 14vmin;
    top: -22vmin;
    /* -child size/2 */
    left: 8vmin;
    /* parent size/2 - child size/2 */
    transition: transform 0.7s linear;
    font-size: 2.5vmin;
    user-select: none;
}

.hidden {
    display: none;
}