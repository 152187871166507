.App {
    text-align: center;
    width: 100%;
    overflow-x: hidden;
    position: absolute;
}

.App-footer {
    background-color: var(--accent-bg-color);
    color: var(--accent-txt-color);
    justify-content: center;
    border-radius: 0;
}

.App-body {
    color: var(--main-txt-color);
    min-height: 100vh;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.main-panel {
    height: 85vmin;
    width: 80vmin;
    display: contents;
}

.main-panel-background {
    transform: scale(1.5);
    position: absolute;
    height: 85vmin;
    width: 80vmin;
    border-radius: 20%;
    background-color: var(--main-panel-color);
    box-shadow: .5vmin .5vmin .5vmin var(--soft-txt-color);
}

.main-panel-extended {
    height: 92vmin;
    width: 80vmin;
    transform: translateY(3vmin) scale(1.5);
    border-radius: 20% 20% 20% 20%;
}

.main-logo {
    transform: scale(1.5);
    height: 30vmin;
    pointer-events: none;
    background: var(--main-bg-color);
    z-index: 1;
    box-shadow: .5vmin .5vmin .5vmin var(--soft-txt-color);
}

@media only screen and (min-width: 600px) {
    .main-logo {
        transform: scale(1);
    }
    .main-panel-background {
        transform: scale(1);
    }
    .main-panel-extended {
        transform: translateY(3vmin) scale(1);
    }
}