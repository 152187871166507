.icons {
    display: contents;
}

.icons-selected {
    display: contents;
    pointer-events: none;
}

.button {
    position: absolute;
    top: .5vmin;
    right: 35.5vmin;
    z-index: 1;
    border: var(--soft-txt-color) 1px solid;
    border-radius: 15%;
    color: var(--main-txt-color);
    background-color: var(--main-bg-color);
    font-size: 2vmin;
    text-align: center;
    padding: 0.7vmin 2vmin;
    user-select: none;
}

.button:hover {
    background-color: var(--soft-txt-color);
}

.button-extension {
    position: absolute;
    width: 15vmin;
    height: 15vmin;
    top: 4vmin;
    right: -2.8vmin;
}