:root {
    --main-bg-color: #f8fafb;
    --main-bg-color-transparent: #999999;
    --main-txt-color: rgb(0, 24, 29);
    --main-txt-color-transparent: rgba(0, 24, 29, 0.8);
    --main-panel-color: #FFFFFF;
    --accent-txt-color: var(--main-bg-color);
    --accent-bg-color: var(--main-txt-color);
    --soft-txt-color: #dadada;
}

body {
    margin: 0;
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--main-bg-color);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}